<template>
  <v-menu offset-y :close-on-click="true">
    <template #activator="{ on }">
      <v-btn
        text
        outlined
        :disabled="!selectedUser"
        color="secondary"
        v-on="on"
      >
        <v-icon left>$dots-vertical</v-icon>
        {{ $trans("additional_options") }}
      </v-btn>
    </template>
    <v-list>
      <v-list-item
        v-for="(el, i) in userActions"
        :key="i"
        @click="el.action(dataToSend, $route.name)"
      >
        <v-list-item-title :class="el.class ? el.class : ''">
          {{ $trans(el.title) }}
        </v-list-item-title>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
import get from "lodash.get";
import { mapActions, mapGetters } from "vuex";
import userActions from "@/calendesk/mixins/userActions";

export default {
  name: "UserAppBarMenu",
  mixins: [userActions],
  computed: {
    ...mapGetters({
      selectedUser: "user/getSelectedUser",
      selectedEmployee: "employee/getSelectedEmployee",
      refreshUsersStatus: "user/shouldRefreshUsers",
    }),
    dataToSend() {
      if (this.selectedUser) {
        return [
          {
            id: this.selectedUser.id,
            user: this.selectedUser,
            employeeId: get(this.selectedEmployee, "id"),
            status: this.selectedUser.status,
          },
        ];
      }

      return [];
    },
    userActions() {
      const actions = [
        {
          title: "send_customer_notification_title",
          action: this.sendCustomerNotification,
        },
      ];

      actions.push({
        title: "reset_password",
        action: this.handleResetPasswordClick,
      });

      actions.push({
        title: this.userIsActive ? "block_account" : "unblock_account",
        action: this.userIsActive ? this.handleLock : this.handleUnlock,
        class: "accent_light--text",
      });

      actions.push({
        title: "delete_user",
        action: this.handleRemove,
        class: "accent_light--text",
      });

      return actions;
    },
    userIsActive() {
      return this.selectedUser && this.selectedUser.status === "active";
    },
  },
  watch: {
    refreshUsersStatus(val) {
      val && this.selectedUser && this.getUser(this.selectedUser.id);
    },
  },
  methods: {
    ...mapActions({
      getUser: "user/get",
    }),
    sendCustomerNotification() {
      this.sendNotificationsToUsers(
        [this.$helpers.cloneObject(this.selectedUser)],
        this.$helpers.customerNotificationCustomerType.user,
        this.$trans("send_customer_notification_title")
      );
    },
    handleResetPasswordClick(data) {
      this.handleResetPassword(data[0].user, false);
    },
  },
};
</script>
